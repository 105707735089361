import React, { useState } from 'react';
import styled from 'styled-components';
import { color, ColorProps } from 'styled-system';
import css from '@styled-system/css';
import { AnimatePresence } from 'framer-motion';
import Animation from './Animation';

const Wrapper = styled.div<ColorProps>`
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${color}
`;

const Button = styled.button`
  height: 50px;
  padding: 14px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2), 0 0 0 rgba(0, 0, 0, 0);
  transition: all 0.3s ease;
  transform: scale(1);

  &:hover {
    box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.4);
    transform: scale(1.1);
  }
`;

const SaveBtn = styled(Button)`
  ${css({ bg: 'accent.1', color: 'text.light.1' })}
  &:hover {
    ${css({ bg: 'accent.0' })}
  }
`;

const ClearBtn = styled(Button)`
  ${css({ bg: 'secondary.3', color: 'text.dark.0' })}
  &:hover {
    ${css({ bg: 'secondary.2' })}
  }
`;

type SavedPositionProps = {
  latitude: number | string | null;
  longitude: number | string | null;
} | null;

type Props = { saveCoords: () => void; clearCoords: () => void; loaded: boolean; savedPosition?: SavedPositionProps };

const Actions = ({ saveCoords, clearCoords, loaded, savedPosition }: Props) => {
  const [isSaved, setIsSaved] = useState(false);

  const onSave = () => {
    saveCoords();
    setIsSaved(true);
  };

  const onClear = () => {
    clearCoords();
    setIsSaved(false);
  };

  let btn = null;

  if (isSaved === true || savedPosition != null) {
    btn = (
      <Animation key="clear-btn">
        <ClearBtn onClick={onClear}>Oublier les coordonées</ClearBtn>
      </Animation>
    );
  } else if (loaded === true) {
    btn = (
      <Animation key="save-btn">
        <SaveBtn onClick={onSave}>Enregistrer les coordonées</SaveBtn>
      </Animation>
    );
  }

  return (
    <Wrapper color="text.light.1">
      <AnimatePresence exitBeforeEnter>{btn}</AnimatePresence>
    </Wrapper>
  );
};

export default Actions;
