import styled from 'styled-components';
import React, { ReactComponentElement } from 'react';
import { color, ColorProps } from 'styled-system';

const ContentWrapper = styled.div<ColorProps>`
  min-height: 100vh;
  height: 100%;
  padding: 50px;

  ${color}
`;

type Props = { children: ReactComponentElement<any> };

const AppLayout = ({ children }: Props) => {
  return (
    <>
      <ContentWrapper bg="primary.0">{children}</ContentWrapper>
    </>
  );
};

export default AppLayout;
