import React from 'react';
import styled from 'styled-components';
import { color, ColorProps } from 'styled-system';

const ContentWrapper = styled.h1<ColorProps>`
  margin: 0;
  font-size: 34px;
  ${color}
`;

type Props = { children: string };

const Title = ({ children }: Props) => {
  return (
    <>
      <ContentWrapper color="text.light.1">{children}</ContentWrapper>
    </>
  );
};

export default Title;
