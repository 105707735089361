import React from 'react';
import styled from 'styled-components';
import { color, ColorProps } from 'styled-system';
import css from '@styled-system/css';
import { motion } from 'framer-motion';
import { DisplayLatLong } from '../DisplayLatLong';

const ContentWrapper = styled.div<ColorProps>`
  margin-top: 60px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  ${color}
`;

const GoogleMapLink = styled.a`
  margin: 80px 0 50px;
  font-weight: 500;
  font-size: 18px;
  text-decoration: none;
  ${css({ color: 'accent.3' })}
  transition: color 0.2s ease;

  &:hover {
    ${css({ color: 'accent.2' })}
  }
`;

type Props = {
  savedPosition?: {
    latitude: number | string | null;
    longitude: number | string | null;
  } | null;
};

const SavedLocation = ({ savedPosition }: Props) => {
  if (savedPosition != null) {
    const { latitude, longitude } = savedPosition;
    return (
      <motion.div color="text.light.1" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
        <ContentWrapper>
          <DisplayLatLong title="Position sauvegardée" latitude={latitude} longitude={longitude} />
          <GoogleMapLink
            target="_blank"
            rel="noopener noreferrer"
            href={`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`}
          >
            Accéder à Google Map
          </GoogleMapLink>
        </ContentWrapper>
      </motion.div>
    );
  }
  return null;
};

export default SavedLocation;
