const colors = {
  black: '#121212',
  white: '#ffffff',
  grays: ['#fafafa', '#f5f5f5', '#eeeeee', '#e0e0e0', '#bdbdbd', '#9e9e9e', '#757575', '#424242'],
  primary: ['#283593', '#3949ab', '#5c6bc0', '#c5cae9'],
  secondary: ['#ff8f00', '#ffb300', '#ffca28', '#ffecb3'],
  accent: ['#00695c', '#00897b', '#26a69a', '#b2dfdb'],
  danger: ['#c62828', '#e53935', '#ef5350', '#ffcdd2'],
  text: {
    dark: ['rgba(0, 0, 0, 0.87)', 'rgba(0, 0, 0, 0.60)', 'rgba(0, 0, 0, 0.38)'],
    light: ['rgba(255,255,255, 0.87)', 'rgba(255,255,255, 0.60)', 'rgba(255,255,255, 0.38)'],
  },
};

export default colors;
