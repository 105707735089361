import colors from './colors';

const LINE_HEIGHT = 26;

const breakpoints = ['40em', '52em', '64em'];
const mediaQueries = {
  small: `@media screen and (min-width: ${breakpoints[0]})`,
  medium: `@media screen and (min-width: ${breakpoints[1]})`,
  large: `@media screen and (min-width: ${breakpoints[2]})`,
};

export default {
  breakpoints,
  buttons: { primary: { color: 'white', bg: 'primary' } },
  colors,
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  fontWeights: { body: 400, heading: 700, bold: 700 },
  fonts: {
    body: 'system-ui, sans-serif',
    heading: 'inherit',
    monospace: 'Menlo, monospace',
  },
  // lineHeights: { body: 1.5, heading: 1.25 },
  mediaQueries,
  // ? md: mediaQueries,
  shadows: {
    small: '0 0 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)',
  },
  space: [0, 5, 10, 20, 40, 80, 160, 320],
  text: {},
  variants: {},
  // sizes: [0, LINE_HEIGHT, 2 * LINE_HEIGHT, 3 * LINE_HEIGHT, 4 * LINE_HEIGHT],
  sizes: [0, LINE_HEIGHT, 2 * LINE_HEIGHT, 3 * LINE_HEIGHT, 4 * LINE_HEIGHT],

  // borders
  // borderWidths
  // borderStyles
  radii: [0, 5, 10, 20],
  // zIndices
  // transitions
};

// Aliases

// space.small = space[1]
// space.medium = space[2]
// space.large = space[3]
