import React, { useState, useEffect } from 'react';
import styled, { withTheme } from 'styled-components';
import { color, ColorProps } from 'styled-system';
import css from '@styled-system/css';
import { motion } from 'framer-motion';

import Clipboard from '../icons/Clipboard';

const Wrapper = styled.div`
  min-width: 280px;
  width: 30vw;
  max-width: 400px;
  height: 160px;
  display: flex;
  flex-flow: column nowrap;
`;

const Head = styled.div`
  width: 100%;
  height: 60px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
`;

const CopyToClipboard = styled.div<ColorProps>`
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${color}
`;

const LatLongWrapper = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
`;

const Value = styled.p(css({ color: 'secondary.1' }));

type Props = {
  latitude: number | string | null;
  longitude: number | string | null;
  theme: { colors: { accent: { [name: string]: string }; text: { light: string[] } } };
  title: string;
};

const DisplayLatLong = ({ theme: { colors }, latitude, longitude, title }: Props) => {
  const [isCopied, setCopied] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCopied(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [isCopied]);

  const onCopy = () => {
    navigator.clipboard.writeText(`${latitude},${longitude}`);
    setCopied(true);
  };

  return (
    <Wrapper>
      <Head>
        <h4>{title}</h4>
        <motion.div animate={{ scale: isCopied ? 1.2 : 1, color: isCopied ? colors.accent[1] : colors.text.light[1] }}>
          <CopyToClipboard onClick={onCopy} title="Copier les coordonées">
            <Clipboard />
          </CopyToClipboard>
        </motion.div>
      </Head>
      <LatLongWrapper>
        <p>Latitude</p>
        <Value>{latitude}</Value>
      </LatLongWrapper>
      <LatLongWrapper>
        <p>Longitude</p>
        <Value color="accent.1">{longitude}</Value>
      </LatLongWrapper>
    </Wrapper>
  );
};

export default withTheme(DisplayLatLong);
