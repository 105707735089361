import React, { ReactComponentElement } from 'react';
import { motion } from 'framer-motion';

type AnimationProps = { children: ReactComponentElement<any>; key: string };

const Animation = ({ children, key }: AnimationProps) => (
  <motion.div
    initial={{ opacity: 0, scale: 0.6 }}
    animate={{ opacity: 1, scale: 1 }}
    exit={{ opacity: 0, scale: 0.6 }}
    key={key}
  >
    {children}
  </motion.div>
);

export default Animation;
