import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import 'normalize.css';

import { theme, GlobalStyle } from '../../style';
import { usePosition } from '../../hooks/usePosition';
import { AppLayout } from '../layouts';
import { Title } from '../Title';
import { CurrentLocation } from '../CurrentLocation';
import { SavedLocation } from '../SavedLocation';
import { Actions } from '../Actions';
type Props = {
  children?: string;
};

function App() {
  const { latitude, longitude, error } = usePosition();
  const [savedPosition, setSavedPosition] = useState<{
    latitude: number | string | null;
    longitude: number | string | null;
  } | null>();

  useEffect(() => {
    const coords = localStorage.getItem('coords');
    let coordsObject = null;
    if (coords != null) {
      coordsObject = JSON.parse(coords);
      setSavedPosition(coordsObject);
    }
  }, []);

  const saveCoords = () => {
    // @ts-ignore
    localStorage.setItem('coords', JSON.stringify({ latitude, longitude }));
    setSavedPosition({ latitude, longitude });
  };

  const clearCoords = () => {
    localStorage.clear();
    setSavedPosition(null);
  };

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <AppLayout>
        <>
          <Title>JSUISGARELA</Title>
          <CurrentLocation latitude={latitude} longitude={longitude} error={error} />
          <Actions
            saveCoords={saveCoords}
            clearCoords={clearCoords}
            loaded={latitude != null && longitude != null}
            savedPosition={savedPosition}
          />
          <SavedLocation savedPosition={savedPosition} />
        </>
      </AppLayout>
    </ThemeProvider>
  );
}

export default App;
