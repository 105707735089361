import React from 'react';
import styled from 'styled-components';
import { color, ColorProps } from 'styled-system';
import { motion } from 'framer-motion';

import { Loader } from '../Loader';
import { DisplayLatLong } from '../DisplayLatLong';

const MainWrapper = styled.div<ColorProps>`
  margin-top: 20px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${color}
`;

const ContentWrapper = styled.div<ColorProps>`
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  ${color}
`;

const Error = styled.p<ColorProps>`
  font-size: 20px;
  ${color}
`;

const LoaderText = styled.p`
  margin-left: 20px;
`;

type Props = {
  latitude: number | null;
  longitude: number | null;
  error: string | null;
};

const AppLayout = ({ latitude, longitude, error }: Props) => {
  let content = (
    <>
      <Loader />
      <LoaderText>Localisation en cours...</LoaderText>
    </>
  );

  if (error != null) {
    content = (
      <div>
        <p>Un problème est survenu :</p>
        <Error color="danger.2">{error}</Error>
      </div>
    );
  }

  if (latitude != null && longitude != null) {
    content = (
      <motion.div color="text.light.1" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
        <ContentWrapper>
          <DisplayLatLong title="Position actuelle" latitude={latitude} longitude={longitude} />
        </ContentWrapper>
      </motion.div>
    );
  }

  return <MainWrapper>{content}</MainWrapper>;
};

export default AppLayout;
